@font-face {
  font-family: 'AfacadBold';
  src: url('assets/fonts/Afacad/static/Afacad-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'TiltWarp';
  src: url('assets/fonts/Tilt_Warp/static/TiltWarp-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Afacad-regular';
  src: url('assets/fonts/Afacad/static/Afacad-Regular.ttf') format('truetype');


}
p {
  font-family: var(--paragraph), sans-serif;

}

:root {
  --primary-color: #ff1423;
  --secondary-color: #0F1223;
  --accent-color: #fff;
  --titles: 'TiltWarp';
  --paragraph: 'Áfacad-regular'
}

h1 {
  font-family: var(--titles);

}



.p-phone {
  font-size: 19px;
}


.img-phone {
  width: 75%;
}

.parent-container {
  display: flex;
  justify-content: center;
  /* Centra horizontalmente */
  align-items: center;
  /* Centra verticalmente */
  height: 100vh;
  /* Ajusta según el tamaño de tu contenedor */
}

.cssbuttons-io-button {
  display: flex;
  align-items: center;
  font-family: inherit;
  cursor: pointer;
  font-weight: 500;
  font-size: 17px;
  padding: 0.8em 1.5em 0.8em 1.2em;
  color: white;
  background: #ad5389;
  background: linear-gradient(0deg,
      var(--primary-color) 0%,
      var(--secondary-color) 800%);
  border: none;
  box-shadow: 0 0.7em 1.5em -0.5em var(--primary-color);
  letter-spacing: 0.05em;
  border-radius: 20em;
}


.cssbuttons-io-button:hover {
  box-shadow: 0 0.5em 1.5em -0.5em var(--primary-color);
}

.cssbuttons-io-button:active {
  box-shadow: 0 0.3em 1em -0.5em #4d36d0be;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.navbar-toggler {
  border: none !important;
  box-shadow: none !important;
}

.dropdown-toggle {
  background-color: transparent;
  /* Ajusta según sea necesario */
  border: none;
  /* Quitar borde */
  color: #fff;
  /* Color de texto */
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  /* Ocultar por defecto */
  position: absolute;
  right: 0;
  background-color: #fff;
  /* Fondo del menú */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.dropdown:hover .dropdown-menu {
  display: block;
  /* Mostrar al pasar el mouse */
}

.dropdown-item {
  padding: 10px 15px;
  color: #000;
  /* Color del texto */
  text-decoration: none;
  /* Sin subrayado */
}

.dropdown-divider {
  height: 1px;
  margin: 0.5rem 0;
  background-color: #e9ecef;
  /* Color del divisor */
}



.custom-navbar {
  background-color: var(--secondary-color);
  /* Cambia este color al que prefieras */
  box-shadow: 0px 10px 20px -5px rgba(255, 0, 0, 0.7); /* Sombra solo abajo */}


.text-nav {
  color: #ffffff !important;
  margin-left: auto;
}

.text-nav::after,
.text-nav::before {
  content: '';
  width: 0%;
  height: 2px;
  background: var(--primary-color);
  display: block;
  transition: 0.5s;
}

.text-nav:hover::after,
.text-nav:hover::before {
  width: 100%;
}

/* From Uiverse.io by StealthWorm */

.hover-scale {
  transition: transform 0.3s ease-in-out;
  /* Añade una transición suave */
}

.hover-scale:hover {
  transform: scale(1.1);
  /* Aumenta la escala al pasar el cursor */
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13rem;
  overflow: hidden;
  height: 3rem;
  background-size: 300% 300%;
  cursor: pointer;
  backdrop-filter: blur(1rem);
  border-radius: 5rem;
  transition: 0.5s;
  animation: gradient_301 5s ease infinite;
  border: double 4px transparent;
  background-image: linear-gradient(#212121, #212121),
    linear-gradient(137.48deg,
      var(--primary-color) 50%,
      var(--secondary-color) 50%,

    );
  background-origin: border-box;
  background-clip: content-box, border-box;
}





.img-cardchat {
  height: 200px;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  position: relative;
}
.container-img-cardchat {
  position: relative; /* Permite que el logo se posicione dentro de este contenedor */
}
.eazt-logo {
  position: absolute;
  top: 10px;  /* Ajusta la distancia desde arriba */
  right: 10px; /* Ajusta la distancia desde la derecha */
  width: 50px; /* Tamaño del logo */
}

#container-stars {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: 0.5s;
  backdrop-filter: blur(1rem);
  border-radius: 5rem;
}

strong {
  z-index: 2;
  font-family: var(--paragraph);
  font-size: 15px;
  letter-spacing: 5px;
  color: #ffffff;
  text-shadow: 0 0 4px white;
}

#glow {
  position: absolute;
  display: flex;
  width: 12rem;
}

.circle {
  width: 100%;
  height: 30px;
  filter: blur(2rem);
  animation: pulse_3011 4s infinite;
  z-index: -1;
}

.circle:nth-of-type(1) {
  background: rgba(254, 83, 186, 0.636);
}

.circle:nth-of-type(2) {
  background: rgba(142, 81, 234, 0.704);
}

.btn:hover #container-stars {
  z-index: 1;
  background-color: #212121;
}

.btn:hover {
  transform: scale(1.1);
}

.btn:active {
  border: double 4px #fe53bb;
  background-origin: border-box;
  background-clip: content-box, border-box;
  animation: none;
}

.btn:active .circle {
  background: #fe53bb;
}

#stars {
  position: relative;
  background: transparent;
  width: 200rem;
  height: 200rem;
}

#stars::after {
  content: "";
  position: absolute;
  top: -10rem;
  left: -100rem;
  width: 100%;
  height: 100%;
  animation: animStarRotate 90s linear infinite;
}

#stars::after {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
}

#stars::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 170%;
  height: 500%;
  animation: animStar 60s linear infinite;
}

#stars::before {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
  opacity: 0.5;
}

@keyframes animStar {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-135rem);
  }
}

@keyframes animStarRotate {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0);
  }
}

@keyframes gradient_301 {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes pulse_3011 {
  0% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}


@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.heading {
  font-family: 'Arial', sans-serif;
  font-size: 2rem;
  font-weight: bold;
}

.text {
  font-family: 'Times New Roman', serif;
  font-size: 1rem;
}

/* From Uiverse.io by cssbuttons-io */
.boton-elegante {
  padding: 15px 30px;
  border: 2px solid #b62e2e;
  background-color: #fff;
  color: #ffffff;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 30px;
  transition: all 0.4s ease;
  outline: none;
  position: relative;
  overflow: hidden;
  font-weight: bold;
}

.boton-elegante::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0) 70%);
  transform: scale(0);
  transition: transform 0.5s ease;
}

.boton-elegante:hover::after {
  transform: scale(4);
}

.boton-elegante:hover {
  border-color: #911616;
  background: #b12828;
}

.container-flex-head {
  padding-top: 150px;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* Ajusta la altura según sea necesario */
  background: linear-gradient(to bottom, var(--secondary-color), #05060c);
  color: white;
  text-align: center;
}

.container-flex-head-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* Ajusta la altura según sea necesario */
  background: linear-gradient(to bottom, #05060c, var(--secondary-color));
  color: white;
  text-align: center;
}

.container-flex-head-3 {

  align-items: center;
  justify-content: center;
  height: 100%;
  /* Ajusta la altura según sea necesario */
  background: linear-gradient(to bottom, var(--secondary-color), #05060c);
  color: white;
  text-align: center;
}


.container-flex-head .content {
  padding: 20px;
  background-color: rgba(252, 252, 252, 1);

  border-radius: 10px;
  /* Bordes redondeados */
}

.article-wrapper {
  position: relative;
  width: 370px;
  height: 280px;
  transition: transform 0.3s ease-in-out;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01),
    0px 105px 63px rgba(0, 0, 0, 0.05),
    0px 47px 47px rgba(0, 0, 0, 0.09),
    0px 12px 26px rgba(0, 0, 0, 0.1),
    0px 0px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;

}

.article-wrapper:hover {
  transform: scale(1.05);

}


.rounded-lg {
  border-radius: 10px;
}

.article-wrapper:hover .project-hover {
  transform: rotate(-45deg);
  background-color: var(--primary-color);
}


.project-title {
  font-size: 30px;
  font-weight: 600;
  display: block;
}



.project-info {

  text-align: left;
  bottom: 10px;
  position: absolute;



}

.project-title {
  font-size: 30px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: black;
  text-align: left;

}



.project-type {
  background: var(--primary-color);
  color: #fdfdfd;
  font-weight: bold;
  padding: 0.3em 0.7em;
  border-radius: 15px;
  font-size: 12px;
  letter-spacing: -0.6px
}

.row-card-restaurant {
  background-color: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  position: absolute;
  top: 5px;
  /* Ajusta según necesites */
  right: 5px;
  /* Ajusta según necesites */
  z-index: 10;
  /* Para que quede encima de la imagen */

}

.row-card-favorite {
  background-color: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  position: absolute;
  top: 5px;
  /* Ajusta según necesites */
  right: 0px;
  /* Ajusta según necesites */
  z-index: 10;
  /* Para que quede encima de la imagen */

}

.project-hover {

  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.container-project {
  width: 100%;
  height: 170px;
  background: gray;
}

.fav-btn-delete {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}



.fav-icon-trash:hover {
  transform: scale(1.2);
}

/* Asegúrate de que el contenedor principal tenga `position: relative` */
.fav-article-wrapper {
  position: relative;
  width: 370px;
  height: 270px;
  transition: transform 0.3s ease-in-out;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01),
    0px 105px 63px rgba(0, 0, 0, 0.05),
    0px 47px 47px rgba(0, 0, 0, 0.09),
    0px 12px 26px rgba(0, 0, 0, 0.1),
    0px 0px 0px rgba(0, 0, 0, 0.1);
}

.fav-img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.fav-container-project {
  border-radius: 10px;
}

.fav-project-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.fav-flex-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.fav-project-title {
  font-size: 30px;
  font-weight: 600;
  overflow: hidden;
  white-space: wrap;
  color: black;
}

.fav-delete-icon-container {
  margin-left: auto;
}

.fav-btn-delete {
  position: absolute;
  top: 5px;
  right: 45px;
  background-color: white;
  /* Fondo blanco */
  border: none;
  cursor: pointer;
  width: 40px;
  /* Tamaño del botón */
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  /* Hace que el fondo sea circular */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  /* Sombra ligera */
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.fav-btn-delete:hover {
  transform: scale(1.1);
  /* Efecto de agrandamiento al pasar el mouse */
  background-color: #f8f8f8;
  /* Cambio de color al pasar el mouse */
}

.fav-icon-trash {
  color: red;
  font-size: 20px;
}

.fav-project-type {
  background: var(--primary-color);
  color: #fdfdfd;
  font-weight: bold;
  padding: 0.3em 0.7em;
  border-radius: 15px;
  font-size: 12px;
  letter-spacing: -0.6px
}

.fav-project-hover {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.fav-article-wrapper:hover .fav-project-hover {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  background-color: var(--primary-color);
}




/*Login*/

.container-log {
  display: flex;
  width: 800px;
  max-width: 90%;
  /* Limita el ancho máximo del contenedor */
  box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01),
    0px 105px 63px rgba(0, 0, 0, 0.05),
    0px 47px 47px rgba(0, 0, 0, 0.09),
    0px 12px 26px rgba(0, 0, 0, 0.1),
    0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 9px;
}

.login-form {
  width: 350px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  border-radius: 9px 0 0 9px;
  background-color: #fff;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0;
}

.title {
  font-weight: 700;
  font-size: 60px;
  line-height: 21px;
  text-align: center;
  color: #2B2B2F;
  margin-bottom: 10px;
}

.description {
  max-width: 80%;
  margin: auto;
  font-weight: 600;
  font-size: 17px;
  text-align: center;
  color: #5F5D6B;
}

.input_container {
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.icon {
  width: 20px;
  position: absolute;
  z-index: 99;
  left: 12px;
  bottom: 9px;
}

.input_field {
  height: 40px;
  padding: 0 0 0 40px;
  border-radius: 7px;
  outline: none;
  border: 1px solid #e5e5e5;
  filter: drop-shadow(0px 1px 0px #efefef) drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.input_field:focus {
  border: 1px solid transparent;
  box-shadow: 0px 0px 0px 2px var(--secondary-color);
  background-color: transparent;
}

.sign-in_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 36px;
  background: linear-gradient(180deg, var(--secondary-color) 0%, var(--secondary-color) 50%, var(--secondary-color) 100%);
  box-shadow: 0px 0.5px 0.5px #EFEFEF, 0px 1px 0.5px rgba(239, 239, 239, 0.5);
  border-radius: 5px;
  border: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  transition: all 0.6s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.sign-in_btn:hover {
  transform: scale(1.01) translateY(-2px);
  box-shadow: 0 10px 20px 0#054eed6b;
}

.testimonial {
  width: 250px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  background: linear-gradient(358.31deg, #fff -24.13%, hsla(0, 0%, 100%, 0) 338.58%), linear-gradient(89.84deg, rgba(230, 36, 174, .15) .34%, rgba(94, 58, 255, .15) 16.96%, rgba(10, 136, 255, .15) 34.66%, rgba(75, 191, 80, .15) 50.12%, rgba(137, 206, 0, .15) 66.22%, rgba(239, 183, 0, .15) 82%, rgba(246, 73, 0, .15) 99.9%);
  border-radius: 0 9px 9px 0;
}

.forgotpass {
  text-decoration: none;
  color: var(--secondary-color);
}

.forgotpass:hover {
  text-decoration: underline;
}

/*Card Chat*/
/* From Uiverse.io by Na3ar-17 */
.container * {
  padding: 0;
  margin: 0;
}

.card_chat {
  width: 600px;
  height: 500px;
  background-color: rgba(15, 18, 35, 0.8);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 40px 1fr;
  padding-inline: 50px; /* Agrega padding a los lados */
}



.card_chat .header {
  display: flex;
  align-items: center;
  position: relative;
}

.card_chat .header .title {
  color: #7dd3fc;
  font-size: 13px;
  padding: 2px 12px;
}

.card_chat .header .copy {
  position: absolute;
  top: 61%;
  right: 1px;
  transform: translate(-50%, -50%);
  color: rgb(100, 116, 139);
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 100;
}

.card_chat .header .copy:hover {
  color: rgb(148, 163, 184);
}

.card_chat .header::before {
  content: "";
  position: absolute;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid #64748b4d;
  right: 1px;
  bottom: 0;
  background-color: #33415580;
  width: 282px;
  height: 33px;
  z-index: 100;
}

.card_chat .header::after {
  content: "";
  position: absolute;
  width: 22%;
  height: 1px;
  background-color: #7dd3fc;
  bottom: 0;
}

.card_chat .footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  padding-left: 10px;
}

.card_chat .footer .code {
  display: flex;
  align-items: center;
}

.card_chat .footer .code .icon {
  color: rgb(244, 114, 182);
  padding-top: 3px;
}

.card_chat .footer .code .text {
  padding-left: 3px;
  color: #f8fafc;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(15, 18, 35, 0.5);
  backdrop-filter: blur(10px);
  /* Efecto blur */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  /* Transición para la visibilidad */
}

.modal-overlay.show {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.2s;
  /* Delay de 0.2 segundos */
}

.modal-content {
  transform: translateY(-50px);
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
  background-color: white;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-overlay.show .modal-content {
  transform: translateY(0);
  opacity: 1;
  transition-delay: 0.2s;
  /* Delay de 0.2 segundos */
}

.animation-container {
  background-color: #262637;
  width: 80%;
  height: 400px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
}

/* From Uiverse.io by boryanakrasteva */
.btn-chat {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  position: relative;
}

.btn-chat::after {

  width: fit-content;
  height: fit-content;
  position: absolute;
  font-size: 15px;
  color: white;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s linear;
  top: 115%;
}

.icons {
  display: flex;
  /* Alinea los íconos en una fila */
  justify-content: center;
  /* Centra horizontalmente los íconos */
  margin-top: 20px;
  /* Añade un margen superior para separarlos del texto */
}

.btn-chat .icon {
  width: 30px;
  height: 30px;
  transition: 0.2s linear;
}

.btn-chat .icon path {
  fill: white;
  transition: 0.2s linear;
}

.btn-chat .icon-red path {
  fill: red;
  transition: 0.2s linear;
}

.btn-chat .icon-favorite path {
  fill: rgb(0, 0, 0);
  transition: 0.2s linear;
}

.btn-chat:hover>.icon {
  transform: scale(1.2);
}

.btn-chat:hover>.icon path {
  fill: rgb(177, 139, 189);
}

.btn-chat:hover::after {
  visibility: visible;
  opacity: 1;
  top: 105%;
}




/*Card Phone */

/* From Uiverse.io by zanina-yassine */
.card-phone {
  width: 100%;
  height: 100%;
  padding: 25px;
  background: radial-gradient(178.94% 106.41% at 26.42% 106.41%, var(--primary-color) 0%, rgba(255, 255, 255, 0) 71.88%)
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    , var(--secondary-color);
  box-shadow: 50px 155px 62px rgba(0, 0, 0, 0.01), 0px 87px 52px rgba(0, 0, 0, 0.05), 0px 39px 39px rgba(0, 0, 0, 0.09), 0px 10px 21px rgba(0, 0, 0, 2.0), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 23px;
  transition: all 0.8s cubic-bezier(0.15, 0.83, 0.66, 1);

}

.card-phone:hover {
  transform: scale(1.05);
}

.animated-text {
  font-size: 20px;
  animation: scaleUp 2s infinite ease-in-out;
}

@keyframes scaleUp {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}








.card-header span:last-child {
  font-weight: 700;
  font-size: 15px;
  line-height: 135%;
  color: rgba(87, 77, 51, 0.33);
}

/*Tutorial Section*/



.tutorial-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0D0D15;
  /* Fondo oscuro */
  padding: 40px;
  position: relative;
}

.content {
  flex: 1;
  color: #fff;
  font-size: 1rem;
  line-height: 1.5;
  margin-right: 40px;
}

.content p {
  margin-bottom: 20px;
}

.video-tutorial {
  flex: 1;
  background-color: rgba(255, 255, 255, 0.05);
  /* Fondo ligeramente transparente */
  border-radius: 8px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.2rem;
  min-height: 200px;
  /* Altura mínima para el tutorial */
}

.tutorial-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 6px;
  width: 150px;
  background-color: #FF003D;
  /* Línea roja */
}

.tutorial-section::after {
  content: '';
  position: absolute;
  top: 0;
  left: 150px;
  height: 6px;
  width: 50px;
  background-color: rgba(255, 255, 255, 0.2);
  /* Extensión translúcida */
}


.images-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
}

.text-container {
  z-index: 1;
  /* Para que el texto siempre esté encima */
}

.image-left {
  width: 300px;
  /* Ajusta el tamaño según necesites */
  transform: rotate(-10deg);
  /* Rotación para la izquierda */
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  /* Color de fondo claro */
  border-radius: 20px;
  /* Redondear el fondo */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  /* Sombra inicial */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* Transición suave */


}

.image-right {
  width: 300px;
  /* Ajusta el tamaño según necesites */
  transform: rotate(-10deg);
  /* Rotación para la izquierda */
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  /* Color de fondo claro */
  border-radius: 20px;
  /* Redondear el fondo */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  /* Sombra inicial */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* Transición suave */

}

.image-wrapper {
  padding: 10px;
  /* Ajusta el tamaño del padding según lo desees */
  background-color: rgba(255, 255, 255, 0.5);
  /* Color de fondo más claro o transparente */
  border-radius: 20px;
  /* Redondear el fondo del contenedor */
  transform: rotate(10deg);
  /* Rotación para la izquierda */
  box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01),
    0px 105px 63px rgba(0, 0, 0, 0.05),
    0px 47px 47px rgba(0, 0, 0, 0.09),
    0px 12px 26px rgba(0, 0, 0, 0.1),
    0px 0px 0px rgba(0, 0, 0, 0.1);
}




.image-right {
  transform: rotate(10deg);
  /* Rotación para la derecha */
}

.image-left:hover,
.image-right:hover {
  transform: rotate(0deg);
  /* Sin rotación al hover */
  transform: scale(1.10);
  /* Efecto de aumento suave */
}

/*Footer*/
.footer {


  background-color: #05060c;
  /* Cambia este color al que prefieras */
  box-shadow: 0 -10px 20px rgba(255, 0, 0, 0.5);
  /* Sombra hacia arriba */

}

/* From Uiverse.io by david-mohseni */
.wrapper {
  display: inline-flex;
  list-style: none;
  height: 120px;
  width: 100%;
  padding-top: 40px;
  font-family: "Poppins", sans-serif;
  justify-content: center;

}

.wrapper .icon {
  position: relative;
  background: #fff;
  border-radius: 50%;
  margin: 10px;
  width: 50px;
  height: 50px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background: #fff;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #fff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wrapper .facebook:hover,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip::before {
  background: #1877f2;
  color: #fff;
}

.wrapper .twitter:hover,
.wrapper .twitter:hover .tooltip,
.wrapper .twitter:hover .tooltip::before {
  background: #1da1f2;
  color: #fff;
}

.wrapper .instagram:hover,
.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip::before {
  background: #e4405f;
  color: #fff;
}


/*card-pricing*/

/* From Uiverse.io by Yaya12085 */
.card-pricing {

  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-bottom: 2rem;
  width: 320px;
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  background-color: var(--secondary-color);
  padding: 1.5rem;
}

.card-pricing .header {
  display: flex;
  flex-direction: column;
}

.card-pricing .header .title {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  color: #fff
}

.price {
  font-size: 3.75rem;
  line-height: 1;
  font-weight: 700;
  color: #fff
}

.desc {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  line-height: 1.625;
  color: rgba(156, 163, 175, 1);
}

.lists {
  margin-bottom: 1.5rem;
  flex: 1 1 0%;
  color: rgba(156, 163, 175, 1);
}

.lists .list {
  margin-bottom: 0.5rem;
  display: flex;
  margin-left: 0.5rem
}

.lists .list svg {
  height: 1.5rem;
  width: 1.5rem;
  flex-shrink: 0;
  margin-right: 0.5rem;
  color: var(--primary-color)
}

.action {
  border: none;
  outline: none;
  display: inline-block;
  border-radius: 0.25rem;
  background-color: var(--primary-color);
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.05em;
  color: #fff
}

.close-button {
  position: absolute;
  top: 10px;
  /* Ajusta según tu diseño */
  right: -70px;
  /* Ajusta según tu diseño */
  background: none;
  border: none;
  color: white;
  /* Cambia el color según el diseño */
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

.close-button:hover {
  color: #ff0000;
  /* Cambia el color al pasar el cursor, si lo deseas */
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Ocupa toda la pantalla */
  background-color: #0F1223; /* Fondo oscuro */
  position: fixed; /* Fijado en la pantalla */
  width: 100%;
  top: 0;
  left: 0;
}

.loading-gif {
  width: 100px; /* Ajusta el tamaño del GIF */
}

@media (max-width: 768px) {
  .card_chat {
    width: 100%;
    /* Ocupa todo el ancho del dispositivo */
    height: 600px;
    grid-template-rows: 50px 1fr;
    /* Ajusta las filas para mayor comodidad */
  }

  .project-title {
    font-size: 25px;
    font-weight: 600;

  }

  .article-wrapper {
    width: 100%;
  }

  .fav-article-wrapper {
    width: 100%;
  }

  .hide-on-mobile {
    display: none;
  }

  .card-phone {
    width: 90%;
    margin-top: 20px;
  }

  .container-flex-head {
    padding-top: 50px;

  }

  .img-association {
    width: 70%;
  }


  .container-flex-head {
    margin-bottom: -1px 

  }
  .container-flex-head-3 {

    padding-top: 40px;

    background:  #05060c;
    padding-bottom: 20px;
    margin-bottom: -1px
  }
  .center-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .p-phone {
    font-size: 15px;
  }
  .close-button {

    right: 10px;

  }
  
.card_chat {

  padding-inline: 30px; /* Agrega padding a los lados */
}

  


}